
import {computed, defineComponent, ref} from 'vue';
import { generalStore } from '@/store';
import router from '@/router';
import {EmployeeFilter, EmployeeSchedule} from "@/models/Employee";
import {api} from "@/services/Api";
import { IdName } from '@/models/Interfaces';
import CheckBoxInput from '@/components/CheckBoxInput.vue';
import {DayOfWeek} from "@/models/Enums";

export default defineComponent({
    name: 'EmployeesFilter',
    components: { CheckBoxInput },
    async setup() {
        const filter = computed<EmployeeFilter>(() => generalStore.state.employeesFilter);
        const tags = computed<IdName[]>(() => generalStore.state.tagsForEmployees);
        const searchTagName = ref();
        const weekDayNames = DayOfWeek;
        const filteredTags = ref<IdName[]>(generalStore.state.tagsForEmployees);

        const searchTags = () => {
            filteredTags.value = JSON.parse(JSON.stringify(tags.value.filter(t => t.name.includes(searchTagName.value))));
        }

        const save = () => {
            generalStore.commit('updateEmployeesFilter', filter.value);
            router.go(-1);
        }

        if (!tags.value.length) {
            const response = await api.getTagsAndProducts();
            if (response.data) {
                generalStore.commit('updateEmployeesTags', response.data.tags);
                filteredTags.value = response.data.tags;
            }
        }

        function clearAll() {
            filter.value.tagIds = [];
            searchTagName.value = '';
            searchTags();
            generalStore.commit('updateEmployeesFilter', filter.value);
        }

        function Close() {
            filter.value.schedules = filter.value.schedules.filter(s => s.active)
            router.push({ name: 'modal-employees-filter' });
        }
        return {
            filter,
            clearAll,
            searchTagName,
            searchTags,
            filteredTags,
            Close,
            save,
        };
    }
});
